<template>
  <div class="app">
    <div class="image">
      <img class="art" :src="albumArt" alt="Album Art" />
      <a :href="spotify_link" target="_blank" class="spotify-icon">
        <i class="fab fa-spotify"></i>
      </a>
    </div>
    <div class="info">
      <h3 class="nowplaying-song">{{ truncatedTitle }}</h3>
      <h4 class="nowplaying-artist">{{ truncatedArtist }}</h4>
      <h4 class="streamer-title">Current Presenter</h4>
      <h4 class="streamer-name">{{ currentPresenter }}</h4>
      <audio ref="audioPlayer">
        <source src="https://listen.deepradio.co.uk/radio.aac" type="audio/aac" />
        <source src="https://listen.deepradio.co.uk/radio.mp3" type="audio/mpeg" />
      </audio>
      <div v-if="isLoading" class="loading-icon">
        <i class="fas fa-spinner fa-spin"></i>
        <span> Loading...</span>
      </div>
      <div class="controls">
        <button @click="togglePlayPause" class="play-pause-button" aria-label="Play/Pause Button">
          <i class="fas" :class="isPlaying ? 'fa-pause' : 'fa-play'"></i>
          <p>{{ isPlaying ? 'Pause' : 'Play' }}</p>
        </button>
        <button
          @click="toggleMute"
          class="volume-button"
          :class="{ 'fade-out': !isPlaying }"
          aria-label="Toggle Mute"
        >
          <i class="fas" :class="isMuted ? 'fa-volume-mute' : 'fa-volume-up'"></i>
          <p>{{ isMuted ? 'Unmute' : 'Mute' }}</p>
        </button>
      </div>
    </div>
  </div>
  <CookieBanner />
  <PWABanner />
</template>

<script>
import CookieBanner from './components/CookieBanner.vue'
import PWABanner from './components/PWABanner.vue'

export default {
  name: 'DeepRadio',
  components: {
    CookieBanner,
    PWABanner
  },
  data() {
    return {
      isPlaying: false,
      isMuted: false,
      isLoading: false,
      songTitle: 'Error loading data...',
      songArtist: 'DeepRadio',
      currentPresenter: 'Error...',
      albumArt: 'images/logo.jpg',
      spotify_link: ''
    }
  },
  computed: {
    truncatedTitle() {
      return this.removeBracketsAndTruncate(this.songTitle)
    },
    truncatedArtist() {
      return this.removeBracketsAndTruncate(this.songArtist)
    }
  },
  methods: {
    togglePlayPause() {
      const audio = this.$refs.audioPlayer
      if (audio) {
        if (audio.paused) {
          this.isLoading = true
          audio.load()
          audio.addEventListener(
            'canplaythrough',
            () => {
              audio.play()
              this.isPlaying = true
              this.isLoading = false
            },
            { once: true }
          )
        } else {
          audio.pause()
          this.isPlaying = false
        }
      }
    },
    toggleMute() {
      const audio = this.$refs.audioPlayer
      audio.muted = !audio.muted
      this.isMuted = audio.muted
    },
    async updateSongInfo() {
      try {
        const response = await fetch('https://api.deepradio.co.uk/api/v1/current')
        if (!response.ok) throw new Error('Error fetching now playing info')

        const data = await response.json()
        const { now_playing } = data

        if (!now_playing) {
          throw new Error('No song currently playing')
        }

        const { title = 'Unknown Title', artist = 'Unknown Artist', art, link } = now_playing

        if (title !== this.songTitle || artist !== this.songArtist) {
          this.songTitle = title
          this.songArtist = artist
          this.albumArt = art
          this.spotify_link = link
          this.setBackground(art)
          this.updateMediaSessionMetadata()
        }
      } catch (error) {
        console.error('Error in updateSongInfo:', error.message)
        this.resetSongInfo()
      }
    },

    async updatePresenter() {
      try {
        const response = await fetch('https://api.deepradio.co.uk/api/v1/presenter')
        if (!response.ok) throw new Error('Error fetching presenter info')

        const data = await response.json()
        const { presenter } = data

        if (!presenter || !presenter.presenter) {
          throw new Error('Presenter info is incomplete')
        }

        this.currentPresenter = presenter.presenter
      } catch (error) {
        console.error('Error updating presenter info:', error.message)
      }
    },

    setBackground(imageUrl) {
      document.body.style.backgroundImage = `url(${imageUrl})`
    },
    updateMediaSessionMetadata() {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: this.songTitle,
          artist: this.songArtist,
          artwork: [{ src: this.albumArt, sizes: '500x500', type: 'image/png' }]
        })
      }
    },
    resetSongInfo() {
      this.songTitle = 'Error loading data...'
      this.songArtist = 'DeepRadio'
      this.currentPresenter = 'DeepRadio 24/7'
      this.albumArt = 'images/logo.jpg'
    },
    removeBracketsAndTruncate(text) {
      const withoutBrackets = text.replace(/\[.*?\]|\(.*?\)/g, '').trim()
      const words = withoutBrackets.split(' ')
      return words.length > 7 ? words.slice(0, 7).join(' ') + '...' : withoutBrackets
    }
  },
  mounted() {
    const audio = this.$refs.audioPlayer
    if (audio) {
      audio.addEventListener('play', () => {
        this.isPlaying = true
      })

      audio.addEventListener('pause', () => {
        this.isPlaying = false
      })

      audio.addEventListener('ended', () => {
        this.isPlaying = false
      })
    }
    this.updateSongInfo()
    this.updatePresenter()
    this.togglePlayPause()
    audio.play()
    setInterval(this.updatePresenter, 5000)
    setInterval(this.updateSongInfo, 5000)
  }
}
</script>

<style>
.app {
  margin-top: 20vh;
  z-index: 1;
  transition: 1s !important;
  display: flex;
  position: absolute;
  left: 16%;
  right: 10%;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.image {
  position: relative;
  display: inline-block;
}

.art {
  display: block;
}

.spotify-icon {
  position: absolute;
  bottom: 20px;
  right: 10px;
  color: #1db954 !important;
  font-size: 50px;
  text-decoration: none;
  transition: transform 0.2s;
}

.spotify-icon:hover {
  transform: scale(1.1);
}

.nowplaying-song,
.nowplaying-artist,
.streamer-title,
.streamer-name {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.art {
  width: 100%;
  max-width: 500px;
  height: auto;
  max-height: calc(100vh - 50px);
  border-radius: 15px;
  margin-bottom: 20px;
  border: #1d1d1d 0.01vw solid;
}

.info {
  padding-left: 10%;
  padding-top: 2%;
  white-space: nowrap;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  position: absolute;
  bottom: 40px;
}

.nowplaying-song,
.nowplaying-artist,
.streamer-title,
.streamer-name {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.02em;
  text-align: left;
  -webkit-user-select: none;
  user-select: none;
}

.nowplaying-song {
  font-size: 2vw;
  font-weight: 700;
}

.nowplaying-artist {
  font-size: 1.5vw;
  color: #fff8;
  margin-top: 0.5vw;
}

.loading-icon {
  color: #fff;
}

.streamer-title {
  font-size: 1.5vw;
  color: #fff8;
  font-weight: 700;
  margin-top: 1.5vw;
  margin-bottom: 0.7vw;
}

.streamer-name {
  font-size: 1.2vw;
  margin-top: 0.3vw;
  margin-bottom: 1vw;
}

button {
  background-color: transparent;
  border: none;
  color: #fff;
  transition: transform 0.2s;
  padding: 1vw 2vw;
  font-size: 1vw;
  margin: 0.5vw 0.25vw;
  cursor: pointer;
  outline: none;
  border-radius: 75px;
}

button:hover {
  transform: scale(1.1);
}

@media (pointer: none), (pointer: coarse) {
  .app {
    margin: 20vh auto 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    left: 10%;
    width: 80%;
  }

  .info {
    padding-left: 5%;
    padding-top: 5%;
    text-align: center;
  }

  .art {
    max-width: 250px;
    border-radius: 15px;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .nowplaying-song {
    font-size: 3vw;
    text-align: center;
  }

  .nowplaying-artist {
    font-size: 2vw;
    text-align: center;
  }

  .streamer-title,
  .streamer-name,
  .loading-icon,
  .mentions {
    display: none;
  }

  button {
    padding: 4vw 5vw;
    font-size: 3vw;
    margin: 0.7vw 0.35vw;
    display: inline-block;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: 100%;
  }
  .spotify-icon {
    position: absolute;
    bottom: 25px;
    right: 10px;
    color: #1db954;
    font-size: 20px;
  }
}

@media screen and (max-height: 500px) {
  .app {
    zoom: 120%;
    margin: 1vh auto 10px;
    padding-bottom: 20px;
  }
  .mentions {
    display: none;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: 100%;
  }

  .spotify-icon {
    position: absolute;
    bottom: 25px;
    right: 10px;
    color: #1db954;
    font-size: 20px;
  }
}
</style>
