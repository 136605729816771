import './main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import Nav from './Nav.vue'
import LowerApp from './LowerApp.vue'
import './registerServiceWorker'
const app = createApp(App)
const nav = createApp(Nav)
const lower = createApp(LowerApp)

app.use(createPinia())

nav.mount('#nav')
app.mount('#app')
lower.mount('#lower')
