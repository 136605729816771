<template>
  <div class="banner" v-if="showBanner">
    <p class="banner_text">
      We use tracking cookies to understand how you use the product and help us improve it. Please
      accept cookies to help us improve.
    </p>
    <button type="button" @click="acceptCookies">Accept cookies</button>
    <button type="button" @click="declineCookies">Decline cookies</button>
  </div>
</template>

<script>
export default {
  name: 'CookieBanner',
  data() {
    return {
      showBanner: true,
      cookieAccepted: false,
      cookieDeclined: false
    }
  },
  methods: {
    acceptCookies() {
      this.showBanner = false
      this.cookieAccepted = true
      localStorage.setItem('cookieAccepted', 'true')
      localStorage.removeItem('cookieDeclined')
    },
    declineCookies() {
      this.showBanner = false
      this.cookieDeclined = true
      localStorage.setItem('cookieDeclined', 'true')
      localStorage.removeItem('cookieAccepted')
    }
  },
  created() {
    if (localStorage.getItem('cookieAccepted')) {
      this.showBanner = false
      this.cookieAccepted = true
    } else if (localStorage.getItem('cookieDeclined')) {
      this.showBanner = false
      this.cookieDeclined = true
    }
  }
}
</script>

<style scoped>
.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0px;
  background-color: #4caf50;
  color: white;
  padding: 15px 3px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.banner p {
  margin: 0;
  flex-grow: 1;
  left: 1px;
}

.banner_text {
  left: 1px;
  padding-left: -10px;
}

.banner button {
  padding: 10px;
  bottom: 10px;
  background-color: white;
  color: #4caf50;
  border: none;
  padding: 10px 20px;
  margin-left: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.banner button:hover {
  background-color: #45a049;
  color: white;
}
</style>
