<template>
  <div class="navbar">
    <img class="logo" src="https://deepradio.co.uk/images/transparent_logo.png" />
  </div>
</template>
<style>
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  background-color: #ffffff20;
  border-bottom: 1px solid #ffffff55;
  z-index: 99999999;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.logo {
  width: 80px;
  height: 80px;
  margin-top: 4px;
  margin-left: 15%;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.5s;
}

@media screen and (max-width: 200px) {
  .navbar {
    display: none;
  }
}

@media screen and (max-height: 500px) {
  .navbar {
    display: none;
  }
}
</style>
