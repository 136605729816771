<template>
  <div class="lower-app">
    <h2>This is the lower section</h2>
    <p>Why are you here anyway.</p>
    <p>send me a message - stefcodes.co.uk</p>
  </div>
</template>

<script>
export default {
  name: 'LowerApp'
}
</script>

<style>
.lower-app {
  position: inherit;
  background-color: #000;
  padding: 20px;
  border-top: 10px solid #ccc;
}
</style>
