<template>
  <div class="banner" v-if="showPWABanner">
    <p class="banner_text">
      Add DeepRadio to your home screen! Just click, <b> Share </b> then <b> Add to Home Screen </b>
    </p>
    <button type="button" @click="closedPWA">Close</button>
  </div>
</template>

<script>
export default {
  name: 'PWABanner',
  data() {
    return {
      showPWABanner: true,
      closePWA: false
    }
  },
  methods: {
    closedPWA() {
      this.showPWABanner = false
      this.closePWA = true
      localStorage.setItem('closedPWA', 'true')
    }
  },
  created() {
    if (localStorage.getItem('closedPWA')) {
      this.showPWABanner = false
      this.closePWA = true
    }
  }
}
</script>

<style scoped>
.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0px;
  background-color: #4caf50;
  color: white;
  padding: 15px 3px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  display: none;
}

.banner p {
  margin: 0;
  flex-grow: 1;
  left: 1px;
}

.banner_text {
  left: 1px;
  padding-left: -10px;
}

.banner button {
  padding: 10px;
  bottom: 10px;
  background-color: white;
  color: #4caf50;
  border: none;
  padding: 10px 30px;
  margin-left: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.banner button:hover {
  background-color: #45a049;
  color: white;
}

@media (pointer: none), (pointer: coarse) {
  .banner {
    display: inline !important;
  }
}
</style>
